import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OurStory } from "./OurStory/OurStory";
import { Events } from "./Events/Events";
import { Home } from "./Home/Home";
import { BridalParty } from "./BridalParty/BridalParty";
import Protected from "./Protected";
import PasswordForm from "./PasswordForm";
import store from "./app/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Hotel } from "./Hotel/Hotel";
import db from "./firebase";
import { onSnapshot, collection } from "firebase/firestore";
import {
  logIn as logInAction,
  secretLogIn as secretLogInAction,
  bridalPartyLogIn as bridalPartyLogInAction,
} from "./features/login/auth";
import { ClothingGuide } from "./ClothingGuide/ClothingGuide";
import { ClothingGuideHinduCeremony } from "./ClothingGuide/ClothingGuideHinduCeremony";
import { ClothingGuideChurchCeremonyReception } from "./ClothingGuide/ClothingGuideChurchCeremonyReception";
import { Gallery } from "./Gallery/Gallery";
import { OurParents } from "./OurParents/OurParents";
import { Game } from "./Game/Game";
import { ContactUs } from "./ContactUs/ContactUs";

export default function AppRouting() {
  const isBridalPartyLoggedIn = useSelector((state) => state.auth.isBridalPartyLoggedIn);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isSecretLoggedIn = useSelector((state) => state.auth.isSecretLoggedIn);
  const dispatch = useDispatch();

  const bridalPartyLogIn = () => {
    dispatch(bridalPartyLogInAction());
  };

  const logIn = () => {
    dispatch(logInAction());
  };

  const secretLogIn = () => {
    dispatch(secretLogInAction());
  };

  useEffect(() => {
    let prevBridalPartyPassword = "";
    let prevPassword = "";
    let prevSecretPassword = "";
    try {
      prevBridalPartyPassword = atob(localStorage.getItem("kideejbridalparty"));
      prevPassword = atob(localStorage.getItem("kideej"));
      prevSecretPassword = atob(localStorage.getItem("kideejsecret"));
    } catch {
      localStorage.removeItem("kideejbridalparty");
      localStorage.removeItem("kideej");
      localStorage.removeItem("kideejsecret");
    }

    onSnapshot(collection(db, "bridal_party_password"), (snapshot) => {
      const bridalPartyPassword = snapshot.docs[0].data().value;
      if (prevBridalPartyPassword === bridalPartyPassword) {
        bridalPartyLogIn();
      }
    });

    onSnapshot(collection(db, "password"), (snapshot) => {
      const password = snapshot.docs[0].data().value;
      if (prevPassword === password) {
        logIn();
      }
    });

    onSnapshot(collection(db, "secret_password"), (snapshot) => {
      const secretPassword = snapshot.docs[0].data().value;
      if (prevSecretPassword === secretPassword) {
        secretLogIn();
      }
    });
  });

  return (
    <BrowserRouter>
      {isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn ? (
        <></>
      ) : (
        <PasswordForm bridalPartyLogIn={bridalPartyLogIn} logIn={logIn} secretLogIn={secretLogIn} />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
              <App />
            </Protected>
          }
        >
          <Route
            index
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Home />
              </Protected>
            }
          />
          <Route
            path="/home"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Home />
              </Protected>
            }
          />
          <Route
            path="*"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Home />
              </Protected>
            }
          />
          <Route
            path="/our-parents"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <OurParents />
              </Protected>
            }
          />
          <Route
            path="/our-story"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <OurStory />
              </Protected>
            }
          />
          <Route
            path="/events"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Events />
              </Protected>
            }
          />
          <Route
            path="/hotel"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Hotel />
              </Protected>
            }
          />
          <Route
            path="/clothing-guide"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <ClothingGuide />
              </Protected>
            }
          />
          <Route
            path="/clothing-guide/hindu-ceremony"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <ClothingGuideHinduCeremony />
              </Protected>
            }
          />
          <Route
            path="/clothing-guide/church-ceremony-reception"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <ClothingGuideChurchCeremonyReception />
              </Protected>
            }
          />
          <Route
            path="/bridal-party"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <BridalParty />
              </Protected>
            }
          />
          <Route
            path="/gallery"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Gallery />
              </Protected>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <ContactUs db={db} />
              </Protected>
            }
          />
          <Route
            path="/game"
            element={
              <Protected isLoggedIn={isLoggedIn || isBridalPartyLoggedIn || isSecretLoggedIn}>
                <Game />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppRouting />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
