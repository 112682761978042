import "./Hotel.css";
import React from "react";

function HotelCard() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-4 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <div className="flex justify-center items-baseline mb-3">
          <span className="text-4xl font-extrabold">Sonesta Select</span>
        </div>
        <img
          className="self-center animate-fade-down animate-duration-800 h-100 w-100 rounded max-w-lg"
          src={"/Sonesta.png"}
          alt=""
        />
        <p className="font-light text-gray-500 pt-4 sm:text-lg">
          35 Foxborough Blvd, Foxborough, MA 02035
        </p>
        <p className="font-light text-gray-500 py-4 sm:text-lg">
          We've reserved a block of rooms at the Sonesta Select hotel which you can reserve through
          the button below! When you book, you should see the room rate at $155/night! On Saturday November 9th we will be providing brunch at the hotel. There
          will also be shuttles to and from the reception in the evening.
        </p>
        <a
          href="https://www.sonesta.com/sonesta-select/ma/foxborough/sonesta-select-boston-foxborough-mansfield?isGroupCode=true&groupCode=2411MUKHOP&checkin=2024-11-07&checkout=2024-11-10"
          target="_blank"
          rel="noreferrer"
          className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Book Hotel room
        </a>
      </div>
    </>
  );
}

export function Hotel(props) {
  return (
    <>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
          <div className="lg:hidden mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Hotel</h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl"></p>
          </div>
          {HotelCard()}
        </div>
      </section>
    </>
  );
}
