import "./App.css";
import { useEffect, useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, Link } from "react-router-dom";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  logOut as logOutAction,
  secretLogOut as secretLogOutAction,
  bridalPartyLogOut as bridalPartyLogOutAction,
} from "./features/login/auth";
import { useDispatch } from "react-redux";
import { onSnapshot, collection } from "firebase/firestore";
import db from "./firebase";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("/");
  const [IsGuestLoggedIn, setIsGuestLoggedIn] = useState(true);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    const prevSecretPassword = atob(localStorage.getItem("kideejsecret"));

    onSnapshot(collection(db, "secret_password"), (snapshot) => {
      const secretPassword = snapshot.docs[0].data().value;
      if (prevSecretPassword === secretPassword) {
        setIsGuestLoggedIn(true);
      } else {
        setIsGuestLoggedIn(false);
      }
    });
  });

  const underlineClass = "underline underline-offset-8";
  const hoverJump = "hover:animate-jump";

  const closeMobileHeader = () => {
    setMobileMenuOpen(false);
  };

  const logOut = () => {
    localStorage.removeItem("kideejbridalparty");
    localStorage.removeItem("kideej");
    localStorage.removeItem("kideejsecret");
    dispatch(logOutAction);
    dispatch(secretLogOutAction);
    dispatch(bridalPartyLogOutAction);
    // window.location.href = "http://localhost:3000/";
    // // Before submitting change to
    window.location.href = "http://kideej.com/";
  };

  const logOutButton = () => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="tooltip" className="tooltip">
            Logout
          </Tooltip>
        }
      >
        <button className="log-out-button" onClick={logOut}>
          <svg
            fill="gray"
            height="20px"
            width="20px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384.971 384.971"
          >
            <g id="SVGRepo_bgCarrier"></g>
            <g id="SVGRepo_tracerCarrier"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <g id="Sign_Out">
                  {" "}
                  <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"></path>{" "}
                  <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"></path>{" "}
                </g>{" "}
                <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
              </g>{" "}
            </g>
          </svg>
        </button>
      </OverlayTrigger>
    );
  };

  const gameButton = () => {
    return (
      <Link
        to="game"
        className={classNames(hoverJump, "flex ml-4 sm:ml-0")}
        onClick={closeMobileHeader}
      >
        <svg
          width="40px"
          height="40px"
          viewBox="0 0 1024 1024"
          className="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M512 512m-480 0a480 480 0 1 0 960 0 480 480 0 1 0-960 0Z"
              fill="#b8cfd9"
            ></path>
            <path
              d="M723.2 704c-25.6 0-51.2-12.8-70.4-32L582.4 582.4H435.2l-38.4 64c-19.2 32-51.2 57.6-89.6 57.6-57.6 0-102.4-44.8-102.4-102.4v-12.8V576l25.6-166.4c6.4-70.4 57.6-121.6 128-121.6 32 0 70.4 12.8 89.6 38.4 25.6 0 89.6-6.4 128 0 25.6-25.6 57.6-32 89.6-32 51.2 0 96 32 115.2 83.2v12.8l38.4 179.2c0 6.4 6.4 19.2 6.4 32 0 51.2-44.8 102.4-102.4 102.4z"
              fill="#345766"
            ></path>
            <path
              d="M716.8 460.8c-12.8 0-19.2-12.8-19.2-19.2s6.4-19.2 19.2-19.2c12.8 0 19.2 12.8 19.2 19.2s-6.4 19.2-19.2 19.2z m-108.8 0c-12.8 0-19.2-12.8-19.2-19.2s6.4-19.2 19.2-19.2c12.8 0 19.2 12.8 19.2 19.2s-6.4 19.2-19.2 19.2z m51.2 57.6c-6.4 0-19.2-6.4-19.2-19.2s6.4-19.2 19.2-19.2 19.2 12.8 19.2 19.2-6.4 19.2-19.2 19.2z m0-115.2c-6.4 0-19.2-6.4-19.2-19.2s6.4-19.2 19.2-19.2 19.2 12.8 19.2 19.2-6.4 19.2-19.2 19.2zM345.6 416h-51.2V448h51.2v51.2h32V448h51.2v-32h-51.2v-51.2h-32z"
              fill="#FFFFFF"
            ></path>
          </g>
        </svg>
      </Link>
    );
  };

  return (
    <header className="bg-white sticky top-0 z-10 shadow">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="home" className="-m-1.5 p-1.5">
            <span className="sr-only">Krystal & Debanjan</span>
            <img className="h-12 w-auto" src="/KD_logo.png" alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="items-center hidden lg:flex lg:gap-x-5">
          <Link
            to="our-story"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/our-story" ? underlineClass : ""
            )}
          >
            Our Story
          </Link>
          <Link
            to="events"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/events" ? underlineClass : ""
            )}
          >
            Events
          </Link>
          <Link
            to="hotel"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/hotel" ? underlineClass : ""
            )}
          >
            Hotel
          </Link>
          <Link
            to="clothing-guide"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/clothing-guide" ? underlineClass : ""
            )}
          >
            Clothing Guide
          </Link>
          <Link
            to="our-parents"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/our-parents" ? underlineClass : ""
            )}
          >
            Our Parents
          </Link>
          <Link
            to="bridal-party"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/bridal-party" ? underlineClass : ""
            )}
          >
            Bridal Party
          </Link>
          <Link
            to="gallery"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/gallery" ? underlineClass : ""
            )}
          >
            Gallery
          </Link>
          <Link
            to="contact-us"
            className={classNames(
              "text-base font-semibold leading-6 text-gray-900",
              hoverJump,
              currentRoute === "/contact-us" ? underlineClass : ""
            )}
          >
            Message Us
          </Link>
          {gameButton()}
        </Popover.Group>
        <div className="hidden lg:grid lg:flex-1 lg:justify-end">
          {!IsGuestLoggedIn && (
            <>
              <a
                href="https://withjoy.com/kideej/rsvp"
                target="_blank"
                rel="noreferrer"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                RSVP <span aria-hidden="true">&rarr;</span>
              </a>
            </>
          )}
        </div>
        <div className="hidden lg:flex">{logOutButton()}</div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="home" onClick={closeMobileHeader} className="-m-1.5 p-1.5">
              <span className="sr-only">Krystal & Debanjan</span>
              <img className="sm:hidden h-12 w-auto" src="/KD_logo.png" alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 animate-spin animate-once animate-duration-300 animate-ease-in"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="our-story"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Our Story
                </Link>
                <Link
                  to="events"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Events
                </Link>
                <Link
                  to="hotel"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Hotel
                </Link>
                <Link
                  to="clothing-guide"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Clothing Guide
                </Link>
                <Link
                  to="our-parents"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Our Parents
                </Link>
                <Link
                  to="bridal-party"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Bridal Party
                </Link>
                <Link
                  to="gallery"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Gallery
                </Link>
                <Link
                  to="contact-us"
                  onClick={closeMobileHeader}
                  className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                >
                  Message Us
                </Link>
                {gameButton()}
              </div>
              <div className="py-6">
                {!IsGuestLoggedIn && (
                  <>
                    <a
                      href="https://withjoy.com/kideej/rsvp"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                    >
                      RSVP
                    </a>
                  </>
                )}
              </div>
              {logOutButton()}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
