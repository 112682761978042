import "./ClothingGuide.css";
import React from "react";

function ClothingCard2() {
  return (
    <>
      <div
        id="hindu-ceremony"
        className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5"
      >
        <h3 className="text-2xl font-semibold">Friday November 8th</h3>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Hindu Ceremony</span>
        </div>
        <img
          className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
          src={"/clothing-guide/HinduCeremonyOutfits.png"}
          alt=""
        />
        <p className="font-light text-gray-500 pt-4 pb-4 sm:text-lg">
          <b>Attire</b>: Mostly Indian, or Western Semi-Formal
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Indian</b>: indowestern, sari, lehenga, kurta
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Western</b>: dress, blazer and pants
        </p>
      </div>
    </>
  );
}

export function ClothingGuideHinduCeremony(props) {
  return (
    <>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">{ClothingCard2()}</div>
      </section>
    </>
  );
}
