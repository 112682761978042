import "./OurParents.css";
import React from "react";
import { useEffect } from "react";

const brideParents = [
  {
    names: "Mr. Pious & Mrs. Rani Kallarackal",
    picture: "/our-parents/ranipious.png",
    title: "Father & Mother of the Bride",
  },
  {
    names: "Mrs. Kunjamma Aurthurmannil & The Late Mr. P.T. Thomas Palathra",
    picture: "/our-parents/achayenammachy.jpg",
    title: "Maternal Grandmother & Grandfather of the Bride",
  },
  {
    names: "The Late Mrs. Ethamma Alencherry",
    picture: "/our-parents/ammachy.jpg",
    title: "Maternal Grandmother of the Bride",
  },
  {
    names: "The Late Mrs. Lilly Mangattu & The Late Mr. P. T. Chacko Kallarackal",
    picture: "/our-parents/papaammachy.jpg",
    title: "Paternal Grandmother & Grandfather of the Bride",
  },
];

const groomParents = [
  {
    names: "Mrs. Lipi & Mr. Nishit Mukhopadhyay",
    picture: "/our-parents/lipinishit.png",
    title: "Mother & Father of the Groom",
  },
  {
    names: "The Late Mr. Nirmal & The Late Mrs. Pratima Banerjee",
    picture: "/our-parents/lipisparents.png",
    title: "Maternal Grandfather & Grandmother of the Groom",
  },
  {
    names: "The Late Mr. Shibanipado & The Late Mrs. Shovarani Mukherjee",
    picture: "/our-parents/babasparents.jpg",
    title: "Paternal Grandfather & Grandmother of the Groom",
  },
];

function ParentCard(parents) {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-4 my-3 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
        <div className="flex justify-center items-baseline mb-3">
          <span className="text-3xl font-extrabold">{parents.names}</span>
        </div>
        <img
          className="self-center animate-fade-down animate-duration-800 max-w-md rounded"
          src={parents.picture}
          alt=""
        />
        <p className="font-light text-gray-500 text-2xl pt-4 lg:text-xl">{parents.title}</p>
      </div>
    </>
  );
}

export function OurParents(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run on component mount

  return (
    <>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
          <div className="lg:hidden mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Our Parents
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl"></p>
          </div>
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
            <div className="col-span-1">
              {brideParents.map((parents) => {
                return <span key={parents.names}> {ParentCard(parents)}</span>;
              })}
            </div>
            <div className="col-span-1">
              {groomParents.map((parents) => {
                return <span key={parents.names}> {ParentCard(parents)}</span>;
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
