import "./Gallery.css";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect } from "react";

function image(imageSrc, tooltip) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        tooltip !== "" ? (
          <Tooltip id="tooltip" className="tooltip">
            {tooltip}
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <img
        className="animate-fade-down animate-duration-800 h-auto max-w-full rounded-lg"
        src={imageSrc}
        alt=""
      />
    </OverlayTrigger>
  );
}

export function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run on component mount

  return (
    <div className="bg-white">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 mb-8 mx-4">
        <div className="grid gap-4">
          <div>
            {image("/gallery/00100lrPORTRAIT_00100_BURST20200816120533533_COVER.jpg", "Onam!")}
          </div>
          <div>
            {image(
              "/gallery/00100lrPORTRAIT_00100_BURST20201017163647182_COVER.jpg",
              "Deej takes his ice cream seriously"
            )}
          </div>
          <div>{image("/gallery/PXL_20230823_230524961.MP.jpg", "")}</div>
          <div>{image("/gallery/PXL_20231001_230507968.MP.jpg", "")}</div>
          <div>{image("/gallery/PXL_20231227_022151577.jpg", "")}</div>
          <div>{image("/gallery/PXL_20240107_191327997.MP.jpg", "")}</div>
          <div>{image("/gallery/20211030_103635.jpg", "")}</div>
          <div>{image("/gallery/received_578346493102751.jpeg", "")}</div>
          <div>
            {image(
              "/gallery/received_2515967552015775.jpeg",
              "Deej meets Kiwi for the first time!"
            )}
          </div>
          <div>{image("/gallery/rtc-snapshot-4651983431802671118-EDIT.jpg", "Deej shaved...")}</div>
          <div>{image("/gallery/20220205_165818.jpg", "")}</div>
        </div>
        <div className="grid gap-4">
          <div>{image("/gallery/00100sPORTRAIT_00100_BURST20201017125759553_COVER.jpg", "")}</div>
          <div>{image("/gallery/20210828_190110.jpg", "")}</div>
          <div>{image("/gallery/20211027_084824.jpg", "")}</div>
          <div>{image("/gallery/20211028_144520.jpg", "")}</div>
          <div>{image("/gallery/IMG_20200417_202111.jpg", "6 Months!")}</div>
          <div>{image("/gallery/5219ECE4-0E6B-4238-9DFD-676FE17FAC62.jpeg", "")}</div>
          <div>{image("/gallery/00100sPORTRAIT_00100_BURST20200912191743501_COVER.jpg", "")}</div>
          <div>{image("/gallery/IMG_20200519_194457-ANIMATION.gif", "")}</div>
          <div>{image("/gallery/20210721_204006.jpg", "")}</div>
          <div>{image("/gallery/Snapchat-1880489893-ANIMATION.gif", "We love Max Brenner")}</div>
          <div>{image("/gallery/TheProposal@picsphere-5.jpg", "Deej surprised Krystal!")}</div>
        </div>
        <div className="grid gap-4">
          <div>{image("/gallery/11401B9E-ECE1-4C56-B402-51F0B00B06E7.jpeg", "")}</div>
          <div>{image("/gallery/20210528_165102.jpg", "")}</div>
          <div>{image("/gallery/Snapchat-463330134.jpg", "")}</div>
          <div>{image("/gallery/20210529_142532.jpg", "Seattle!")}</div>
          <div>{image("/gallery/IMG_20200524_145326.jpg", "")}</div>
          <div>{image("/gallery/IMG_20200624_220239.jpg", "The golden snitch!")}</div>
          <div>
            {image(
              "/gallery/Snapchat-547739674.jpg",
              "And on our second date, Deej ordered dessert just for himself"
            )}
          </div>
          <div>{image("/gallery/20220501_162049.jpg", "Miami!")}</div>
          <div>{image("/gallery/Screenshot_20191221-185510.png", "Our future!")}</div>
          <div>
            {image(
              "/gallery/20210710_131827.jpg",
              "Also our future (on the soccer field) #soccermom"
            )}
          </div>
        </div>
        <div className="grid gap-4">
          <div>{image("/gallery/TheProposal@picsphere-8.jpg", "The proposal!")}</div>
          <div>{image("/gallery/Snapchat-1147422154.jpg", "")}</div>
          <div>{image("/gallery/20210813_184640.jpg", "")}</div>
          <div>{image("/gallery/00000IMG_00000_BURST20200215195408937_COVER.jpg", "")}</div>
          <div>{image("/gallery/20221031_190350.jpg", "")}</div>
          <div>{image("/gallery/IMG_20191108_231522.jpg", "Our first picture together!")}</div>
          <div>{image("/gallery/MVIMG_20200103_211826.jpg", "Deej loves his dessert!")}</div>
          <div>{image("/gallery/PXL_20210214_234849353.PORTRAIT.jpg", "")}</div>
          <div>{image("/gallery/20220905_124942.jpg", "")}</div>
          <div>{image("/gallery/Snapchat-1824524611.jpg", "First covid masks!")}</div>
        </div>
      </div>
    </div>
  );
}
