import { useState } from "react";
import "./ContactUs.css";
import { doc, setDoc } from "firebase/firestore";

/** Components */
const Card = (props) => (
  <div className="card">
    {/*<div className="waves">
      </div>*/}
    {props.children}
  </div>
);

const Form = (props) => <form className="form">{props.children}</form>;

const TextInput = (props) => (
  <div className="text-input">
    <label className={props.focus || props.value !== "" ? "label-focus" : ""} htmlFor={props.name}>
      {props.label}
    </label>
    <input
      className={props.focus || props.value !== "" ? "input-focus" : ""}
      type="text"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onInput={props.onInput}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  </div>
);

const TextArea = (props) => (
  <div className="text-area">
    <label className={props.focus || props.value !== "" ? "label-focus" : ""} htmlFor={props.name}>
      {props.label}
    </label>
    <textarea
      className={props.focus || props.value !== "" ? "input-focus" : ""}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onInput={props.onInput}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  </div>
);

/** Root Component */
export function ContactUs(props) {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState({
    name: "name",
    label: "Name",
    value: "",
    focus: false,
  });

  const [email, setEmail] = useState({
    name: "email",
    label: "Email",
    value: "",
    focus: false,
  });
  const [message, setMessage] = useState({
    name: "message",
    label: "Message",
    value: "",
    focus: false,
  });

  function handleFocusName(e) {
    const newName = { ...name, value: e.target.value, focus: true };
    setName(newName);
  }

  function handleBlurName(e) {
    const newName = { ...name, value: e.target.value, focus: false };
    setName(newName);
  }

  function handleChangeName(e) {
    const newName = { ...name, value: e.target.value };
    setName(newName);
  }

  function handleFocusEmail(e) {
    const newEmail = { ...email, value: e.target.value, focus: true };
    setEmail(newEmail);
  }

  function handleBlurEmail(e) {
    const newEmail = { ...email, value: e.target.value, focus: false };
    setEmail(newEmail);
  }

  function handleChangeEmail(e) {
    const newEmail = { ...email, value: e.target.value };
    setEmail(newEmail);
  }

  function handleFocusMessage(e) {
    const newMessage = { ...message, value: e.target.value, focus: true };
    setMessage(newMessage);
  }

  function handleBlurMessage(e) {
    const newMessage = { ...message, value: e.target.value, focus: false };
    setMessage(newMessage);
  }

  function handleChangeMessage(e) {
    const newMessage = { ...message, value: e.target.value };
    setMessage(newMessage);
  }

  function generateUniqueId() {
    const cryptoObj = window.crypto || window.msCrypto; // for compatibility with older browsers
    if (!cryptoObj || !cryptoObj.getRandomValues) {
      // Crypto API not supported, fallback to Math.random
      return Math.random().toString(36).substr(2, 9); // Generate a random string
    }

    // Generate an array of 16 random bytes
    const array = new Uint8Array(16);
    cryptoObj.getRandomValues(array);

    // Convert the array to a hexadecimal string
    let id = "";
    for (let i = 0; i < array.length; i++) {
      id += (array[i] + 0x100).toString(16).substr(1); // Padding with '0' if necessary
    }

    return id;
  }

  async function sendMessage(event) {
    event.preventDefault();

    const currentDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // Set to false for 24-hour format
    };

    const formattedDateTime = currentDate.toLocaleDateString("en-US", options);

    const data = {
      name: name.value,
      email: email.value,
      message: message.value,
      time: formattedDateTime,
    };

    await setDoc(doc(props.db, "contact_form", generateUniqueId()), data)
      .then(() => {
        setIsFormVisible(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div className="contact-us">
      <Card>
        {isFormVisible && <h1>Send us a Message!</h1>}
        {!isFormVisible && <h1>Thank you!</h1>}
        <Form>
          {isFormVisible && (
            <>
              <TextInput
                {...name}
                onFocus={handleFocusName}
                onBlur={handleBlurName}
                onChange={handleChangeName}
              />
              <TextInput
                {...email}
                onFocus={handleFocusEmail}
                onBlur={handleBlurEmail}
                onChange={handleChangeEmail}
              />
              <TextArea
                {...message}
                onFocus={handleFocusMessage}
                onBlur={handleBlurMessage}
                onChange={handleChangeMessage}
              />
              {!isLoading && (
                <button
                  className="button"
                  disabled={name.value === "" || message.value === ""}
                  onClick={sendMessage}
                >
                  Send
                </button>
              )}
              {isLoading && (
                <button className="button" disabled={true}>
                  Sending...
                </button>
              )}
            </>
          )}
        </Form>
      </Card>
    </div>
  );
}
