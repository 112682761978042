import React, { useState, useEffect } from "react";
import db from "./firebase";
import { onSnapshot, collection } from "firebase/firestore";
import "./App.css";
import Form from "react-bootstrap/Form";

const PasswordForm = ({ bridalPartyLogIn, logIn, secretLogIn }) => {
  const [passwordAttempt, setPasswordAttempt] = useState("");
  const [bridalPartyPasswordReal, setBridalPartyPasswordReal] = useState("");
  const [passwordReal, setPasswordReal] = useState("");
  const [secretPasswordReal, setSecretPasswordReal] = useState("");
  const [passwordType, setpasswordType] = useState("password");

  const handlePasswordChange = (e) => {
    setPasswordAttempt(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (passwordAttempt === bridalPartyPasswordReal) {
      localStorage.setItem("kideejbridalparty", btoa(passwordAttempt));
      bridalPartyLogIn();
    } else if (passwordAttempt === passwordReal) {
      localStorage.setItem("kideej", btoa(passwordAttempt));
      logIn();
    } else if (passwordAttempt === secretPasswordReal) {
      localStorage.setItem("kideejsecret", btoa(passwordAttempt));
      secretLogIn();
    } else {
      alert("Incorrect password");
    }
  };

  useEffect(() => {
    onSnapshot(collection(db, "bridal_party_password"), (snapshot) => {
      setBridalPartyPasswordReal(snapshot.docs[0].data().value);
    });

    onSnapshot(collection(db, "password"), (snapshot) => {
      setPasswordReal(snapshot.docs[0].data().value);
    });

    onSnapshot(collection(db, "secret_password"), (snapshot) => {
      setSecretPasswordReal(snapshot.docs[0].data().value);
    });
  });

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setpasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  return (
    <div className="bg-white password-background">
      <div className="password-container">
        <span className="password-label">Password:</span>
        <input
          className="password-input"
          type={passwordType}
          value={passwordAttempt}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
        />
        <span className="password-reveal" onClick={showHide}>
          {passwordType === "text" ? (
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="gray">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M876.8 156.8c0-9.6-3.2-16-9.6-22.4-6.4-6.4-12.8-9.6-22.4-9.6-9.6 0-16 3.2-22.4 9.6L736 220.8c-64-32-137.6-51.2-224-60.8-160 16-288 73.6-377.6 176C44.8 438.4 0 496 0 512s48 73.6 134.4 176c22.4 25.6 44.8 48 73.6 67.2l-86.4 89.6c-6.4 6.4-9.6 12.8-9.6 22.4 0 9.6 3.2 16 9.6 22.4 6.4 6.4 12.8 9.6 22.4 9.6 9.6 0 16-3.2 22.4-9.6l704-710.4c3.2-6.4 6.4-12.8 6.4-22.4Zm-646.4 528c-76.8-70.4-128-128-153.6-172.8 28.8-48 80-105.6 153.6-172.8C304 272 400 230.4 512 224c64 3.2 124.8 19.2 176 44.8l-54.4 54.4C598.4 300.8 560 288 512 288c-64 0-115.2 22.4-160 64s-64 96-64 160c0 48 12.8 89.6 35.2 124.8L256 707.2c-9.6-6.4-19.2-16-25.6-22.4Zm140.8-96c-12.8-22.4-19.2-48-19.2-76.8 0-44.8 16-83.2 48-112 32-28.8 67.2-48 112-48 28.8 0 54.4 6.4 73.6 19.2L371.2 588.8ZM889.599 336c-12.8-16-28.8-28.8-41.6-41.6l-48 48c73.6 67.2 124.8 124.8 150.4 169.6-28.8 48-80 105.6-153.6 172.8-73.6 67.2-172.8 108.8-284.8 115.2-51.2-3.2-99.2-12.8-140.8-28.8l-48 48c57.6 22.4 118.4 38.4 188.8 44.8 160-16 288-73.6 377.6-176C979.199 585.6 1024 528 1024 512s-48.001-73.6-134.401-176Z"
                  fill="gray"
                ></path>
                <path
                  d="M511.998 672c-12.8 0-25.6-3.2-38.4-6.4l-51.2 51.2c28.8 12.8 57.6 19.2 89.6 19.2 64 0 115.2-22.4 160-64 41.6-41.6 64-96 64-160 0-32-6.4-64-19.2-89.6l-51.2 51.2c3.2 12.8 6.4 25.6 6.4 38.4 0 44.8-16 83.2-48 112-32 28.8-67.2 48-112 48Z"
                  fill="gray"
                ></path>
              </g>
            </svg>
          ) : (
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="gray">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M0 0h48v48H0z" fill="none"></path>{" "}
                <g id="Shopicon">
                  {" "}
                  <path d="M24,38c12,0,20-14,20-14s-8-14-20-14S4,24,4,24S12,38,24,38z M24,14c7.072,0,12.741,6.584,15.201,9.992 C36.728,27.396,31.024,34,24,34c-7.072,0-12.741-6.584-15.201-9.992C11.272,20.604,16.976,14,24,14z"></path>{" "}
                  <path d="M24,32c4.418,0,8-3.582,8-8s-3.582-8-8-8s-8,3.582-8,8S19.582,32,24,32z M24,20c2.206,0,4,1.794,4,4c0,2.206-1.794,4-4,4 s-4-1.794-4-4C20,21.794,21.794,20,24,20z"></path>{" "}
                </g>{" "}
              </g>
            </svg>
          )}
        </span>
        <button
          className="password-submit ml-4 rounded-lg bg-primary-600 px-3.5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          type="submit"
          onClick={handlePasswordSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PasswordForm;
