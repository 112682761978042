import { createContext, useCallback, useEffect, useReducer } from "react";
import { isNil, throttle } from "lodash";
import { mergeAnimationDuration, tileCountPerDimension } from "../constants";
import gameReducer, { initialState } from "../reducers/game-reducer";

//   type MoveDirection = "move_up" | "move_down" | "move_left" | "move_right";

export const GameContext = createContext({
  score: 0,
  moveTiles: () => {},
  isGameOver: () => {},
  isWinTrue: () => {},
  getTiles: () => [],
  resetTiles: () => {},
  startGame: () => {},
});

export default function GameProvider({ children }) {
  const [gameState, dispatch] = useReducer(gameReducer, initialState);

  const getEmptyCells = () => {
    const results = [];

    for (let x = 0; x < tileCountPerDimension; x++) {
      for (let y = 0; y < tileCountPerDimension; y++) {
        if (isNil(gameState.board[y][x])) {
          results.push([x, y]);
        }
      }
    }
    return results;
  };

  const isGameOver = () => {
    return gameState.isGameOver;
  };

  const isWinTrue = () => {
    return gameState.isWinTrue;
  };

  const isBoardFull = () => {
    if (gameState.tilesByIds.length === 16) {
      dispatch({ type: "is_game_over" });
    }
  };

  const appendRandomTile = () => {
    const emptyCells = getEmptyCells();
    if (emptyCells.length > 0) {
      const cellIndex = Math.floor(Math.random() * emptyCells.length);
      const randomNumber = Math.random();

      // Round the number to either 0 or 1
      const pickedNumber = Math.round(randomNumber);
      const value = pickedNumber === 0 ? 2 : 4;
      const newTile = {
        position: emptyCells[cellIndex],
        value,
      };
      dispatch({ type: "create_tile", tile: newTile });
    }
  };

  const getTiles = () => {
    return gameState.tilesByIds.map((tileId) => gameState.tiles[tileId]);
  };

  const moveTiles = useCallback(
    throttle((type) => dispatch({ type }), mergeAnimationDuration * 1.05, { trailing: false }),
    [dispatch]
  );

  const resetTiles = () => {
    dispatch({ type: "reset" });
  };

  const startGame = () => {
    dispatch({ type: "create_tile", tile: { position: [0, 1], value: 2 } });
    dispatch({ type: "create_tile", tile: { position: [0, 2], value: 2 } });
  };

  useEffect(() => {
    if (gameState.hasChanged) {
      setTimeout(() => {
        dispatch({ type: "clean_up" });
        appendRandomTile();
      }, mergeAnimationDuration);
    }
  }, [gameState.hasChanged]);

  useEffect(() => {
    isBoardFull();
  }, [gameState.tilesByIds]);

  return (
    <GameContext.Provider
      value={{
        score: gameState.score,
        isGameOver,
        isWinTrue,
        getTiles,
        moveTiles,
        resetTiles,
        startGame,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
