import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./Game.css";
import Tile from "./Tile";
import { GameContext } from "./context/game-context";
import MobileSwiper from "./MobileSwiper";
import Modal from "react-modal";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import db from "../firebase";

export default function Board() {
  const [shownWinBefore, setShownWinBefore] = useState(false);
  const [showWin, setShowWin] = useState(false);
  const { getTiles, moveTiles, isGameOver, resetTiles, startGame, isWinTrue } =
    useContext(GameContext);
  const [showNameModal, setShowNameModal] = useState(false);
  const [name, setName] = useState({
    name: "name",
    label: "Name",
    value: "",
    focus: false,
  });

  const initialized = useRef(false);

  const handleClose = () => setShowNameModal(false);
  const handleShow = () => setShowNameModal(true);

  const reset = () => {
    resetTiles();
    startGame();
  };

  const submitScore = () => {
    handleShow();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "5",
      width: "70vw",
      height: "200px",
    },
  };

  function handleFocusName(e) {
    const newName = { ...name, value: e.target.value, focus: true };
    setName(newName);
  }

  function handleBlurName(e) {
    const newName = { ...name, value: e.target.value, focus: false };
    setName(newName);
  }

  function handleChangeName(e) {
    const newName = { ...name, value: e.target.value };
    setName(newName);
  }

  function generateUniqueId() {
    const cryptoObj = window.crypto || window.msCrypto; // for compatibility with older browsers
    if (!cryptoObj || !cryptoObj.getRandomValues) {
      // Crypto API not supported, fallback to Math.random
      return Math.random().toString(36).substr(2, 9); // Generate a random string
    }

    // Generate an array of 16 random bytes
    const array = new Uint8Array(16);
    cryptoObj.getRandomValues(array);

    // Convert the array to a hexadecimal string
    let id = "";
    for (let i = 0; i < array.length; i++) {
      id += (array[i] + 0x100).toString(16).substr(1); // Padding with '0' if necessary
    }

    return id;
  }

  async function handleSubmitScore(event) {
    event.preventDefault();

    const now = Timestamp.now();

    const currentHighScore = localStorage.getItem("kideejhighscore");

    const data = {
      name: name.value,
      score: Number(currentHighScore),
      timestamp: now,
      show: true,
    };

    await setDoc(doc(db, "leaderboard", generateUniqueId()), data)
      .then(() => {
        setShowNameModal(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const nameModal = () => {
    return (
      <Modal
        isOpen={showNameModal}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <button onClick={handleClose} className="float-right">
          <svg
            width="20px"
            height="20px"
            viewBox="-0.5 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#808080"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M3 21.32L21 3.32001"
                stroke="#808080"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M3 3.32001L21 21.32"
                stroke="#808080"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </button>
        <div className="name-modal-contact-us">
          <input
            className="name-modal-text-input"
            type="text"
            placeholder="Your name"
            name={name.name}
            value={name.value}
            focus={name.focus}
            onFocus={handleFocusName}
            onBlur={handleBlurName}
            onChange={handleChangeName}
          />
          <button
            className="name-modal-send-button"
            disabled={name.value === ""}
            onClick={handleSubmitScore}
          >
            Send
          </button>
        </div>
      </Modal>
    );
  };

  const handleKeyDown = useCallback(
    (e) => {
      // disables page scrolling with keyboard arrows
      e.preventDefault();

      switch (e.code) {
        case "ArrowUp":
          moveTiles("move_up");
          break;
        case "ArrowDown":
          moveTiles("move_down");
          break;
        case "ArrowLeft":
          moveTiles("move_left");
          break;
        case "ArrowRight":
          moveTiles("move_right");
          break;
        default:
          break;
      }
    },
    [moveTiles]
  );

  const handleSwipe = useCallback(
    ({ deltaX, deltaY }) => {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > 0) {
          moveTiles("move_right");
        } else {
          moveTiles("move_left");
        }
      } else {
        if (deltaY > 0) {
          moveTiles("move_down");
        } else {
          moveTiles("move_up");
        }
      }
    },
    [moveTiles]
  );

  const renderGrid = () => {
    const cells = [];
    const totalCellsCount = 16;

    for (let index = 0; index < totalCellsCount; index += 1) {
      cells.push(<div className="cell" key={index} />);
    }

    return cells;
  };

  const renderTiles = () => {
    return getTiles().map((tile) => <Tile key={`${tile?.id}`} {...tile} />);
  };

  useEffect(() => {
    if (initialized.current === false) {
      startGame();
      initialized.current = true;
    }
  }, [startGame]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (showNameModal) {
      window.removeEventListener("keydown", handleKeyDown);
    } else {
      window.addEventListener("keydown", handleKeyDown);
    }
  }, [showNameModal]);

  useEffect(() => {
    if (isWinTrue() && !shownWinBefore) {
      setShowWin(true);
      setShownWinBefore(true);
    }
  }, [isWinTrue()]);

  const continuePlaying = () => {
    setShowWin(false);
  };

  return (
    <>
      <MobileSwiper onSwipe={handleSwipe} showNameModal={showNameModal} isGameOver={isGameOver()}>
        {isGameOver() && (
          <div className="game-over-panel text-5xl">
            <span className="font-extrabold mb-8">GAME OVER</span>
            <div>
              <button
                onClick={reset}
                className="ml-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                New Game
              </button>
              <button
                onClick={submitScore}
                className="ml-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Submit Score
              </button>
            </div>
          </div>
        )}
        {showWin && (
          <div className="game-over-panel text-5xl">
            <span className="font-extrabold mb-8">You won!</span>
            <button
              onClick={continuePlaying}
              className="ml-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Continue playing
            </button>
          </div>
        )}
        <div className="board">
          <div className="tiles">{renderTiles()}</div>
          <div className="grid-2048">{renderGrid()}</div>
        </div>
      </MobileSwiper>
      {nameModal()}
    </>
  );
}
