import { FlipBook } from "../FlipBook/FlipBook";
import "./OurStory.css";
import React from "react";

export function OurStory(props) {
  return (
    <section className="bg-white my-12">
      <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
        <div className="lg:hidden mx-auto max-w-screen-md text-center mb-8 lg:mb-8">
          <h2 className="text-4xl tracking-tight font-extrabold text-gray-900">Our Story</h2>
        </div>
        <FlipBook />
      </div>
    </section>
  );
}
