import "./BridalParty.css";
import React from "react";
import { useEffect } from "react";

const bestman = [
  {
    name: "Deba Mukerji",
    role: "Best Man",
    imageUrl: "/bridal-party/deba.png",
  },
];

const groomsmen = [
  {
    name: "Abir Deb",
    role: "Groomsman",
    imageUrl: "/bridal-party/abir.png",
  },
  {
    name: "Ajay Dhesikan",
    role: "Groomsman",
    imageUrl: "/bridal-party/ajay.png",
  },
  {
    name: "Arjun Kiran",
    role: "Groomsman",
    imageUrl: "/bridal-party/arjun.png",
  },
  {
    name: "Bhasha Mukhopadhyay",
    role: "Groomswoman",
    imageUrl: "/bridal-party/bhasha.png",
  },
  {
    name: "Brandon Auger",
    role: "Groomsman",
    imageUrl: "/bridal-party/brandon.png",
  },
  {
    name: "Edward Smongeski",
    role: "Groomsman",
    imageUrl: "/bridal-party/edward.png",
  },
  {
    name: "Indranil Banerjee",
    role: "Groomsman",
    imageUrl: "/bridal-party/indranil.png",
  },
  {
    name: "Kaushik Banerjee",
    role: "Groomsman",
    imageUrl: "/bridal-party/kaushik.png",
  },
  {
    name: "Kevin Liang",
    role: "Groomsman",
    imageUrl: "/bridal-party/kevin.png",
  },
  {
    name: "Kris Goncalves",
    role: "Groomsman",
    imageUrl: "/bridal-party/kris.png",
  },
  {
    name: "Laura Stapler",
    role: "Groomswoman",
    imageUrl: "/bridal-party/laura.png",
  },
  {
    name: "Mithila Mahesh",
    role: "Groomswoman",
    imageUrl: "/bridal-party/mitoo.png",
  },
  {
    name: "Nate Richardson",
    role: "Groomsman",
    imageUrl: "/bridal-party/nate.png",
  },
  {
    name: "Nikita Patel",
    role: "Groomswoman",
    imageUrl: "/bridal-party/nikita.png",
  },
  {
    name: "Nisarg Chhaya",
    role: "Groomsman",
    imageUrl: "/bridal-party/nisarg.png",
  },
  {
    name: "Roop Dutta",
    role: "Groomsman",
    imageUrl: "/bridal-party/roop.png",
  },
  {
    name: "Ryan Kimmit",
    role: "Groomsman",
    imageUrl: "/bridal-party/ryan.png",
  },
  {
    name: "Tom Imbalzano",
    role: "Groomsman",
    imageUrl: "/bridal-party/tom.png",
  },
];

const maidsOfHonor = [
  // Bridesmaids
  {
    name: "Jake Kallarackal",
    role: "Man of Honor",
    imageUrl: "/bridal-party/jake.png",
  },
  {
    name: "Derek Kallarackal",
    role: "Man of Honor",
    imageUrl: "/bridal-party/derek.png",
  },
];

const bridesmaids = [
  {
    name: "Alice Aragam",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/alice.png",
  },
  {
    name: "Ami Patel",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/ami.png",
  },
  {
    name: "Brigit Thomas",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/brigit.png",
  },
  {
    name: "Chitanya Gopu",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/chitanya.png",
  },
  {
    name: "Cindy Wu",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/cindy.png",
  },
  {
    name: "Erin Schuster",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/erin.png",
  },
  {
    name: "Jyothi Singh",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/jyothi.png",
  },
  {
    name: "Livia Thomas",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/livia.png",
  },
  {
    name: "Maria Thomas",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/maria.png",
  },
  {
    name: "Maya Hareli",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/maya.png",
  },
  {
    name: "Natasha Chabria",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/natasha.png",
  },
  {
    name: "Nina Thomas",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/nina.png",
  },
  {
    name: "Robin Pottackal",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/robin.png",
  },
  {
    name: "Shivani Patel",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/shivani.png",
  },
  {
    name: "Theresa Thomas",
    role: "Bridesmaid",
    imageUrl: "/bridal-party/theresa.png",
  },
  // More people...
];

const ringBearers = [
  {
    name: "Arvin Joseph Thomas", // double check
    role: "",
    imageUrl: "/bridal-party/aj.png",
  },
  {
    name: "Gabriel Pottackal",
    role: "",
    imageUrl: "/bridal-party/gabriel.png",
  },
  {
    name: "George Pottackal",
    role: "",
    imageUrl: "/bridal-party/george.png",
  },
  {
    name: "Luke Jacob Thomas", // double check
    role: "",
    imageUrl: "/bridal-party/luke.png",
  },
  {
    name: "Vishwas Singh",
    role: "",
    imageUrl: "/bridal-party/vishwas.png",
  },
  // More people...
];

const flowerGirl = [
  {
    name: "Jaiya Singh",
    role: "",
    imageUrl: "/bridal-party/jaiya.png",
  },
];

export function displayPeople(people) {
  return (
    <>
      {people.map((person) => (
        <li key={person.name}>
          <div className="flex items-center gap-x-4">
            <img
              className="animate-jump-in animate-duration-500 h-32 w-32 rounded-full"
              src={person.imageUrl}
              alt=""
            />
            <div>
              <h3 className="text-base text-left font-semibold leading-7 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-sm text-left font-semibold leading-6 text-primary-600">
                {person.role}
              </p>
            </div>
          </div>
        </li>
      ))}
    </>
  );
}

export function BridalParty(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run on component mount

  return (
    <div className="bg-white pb-12 sm:pb-32 pt-12">
      <div className="mx-auto max-w-7xl gap-x-8 gap-y-20 px-6">
        <div className="mb-12">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet our Bridal Party
          </h2>
        </div>
        <ul className="grid gap-x-4 gap-y-12 my-12 sm:grid-cols-4 sm:gap-y-16">
          <li></li>
          {displayPeople(maidsOfHonor)}
          <li></li>
        </ul>
        <ul className="grid gap-x-4 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-4">
          {displayPeople(bridesmaids)}
        </ul>
        <ul className="grid gap-x-4 gap-y-12 my-12 sm:grid-cols-5 sm:gap-y-16">
          <li></li>
          <li></li>
          {displayPeople(bestman)}
          <li></li>
          <li></li>
        </ul>
        <ul className="grid gap-x-4 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-4">
          {displayPeople(groomsmen)}
        </ul>
      </div>
      <div className="mt-32 sm:mt-16"></div>
      <div className="mx-auto max-w-7xl gap-x-8 gap-y-20 px-6">
        <div className="mb-12">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Flower Girl
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600"></p>
        </div>

        <ul className="grid gap-x-4 gap-y-12 my-12 sm:grid-cols-5 sm:gap-y-16">
          <li></li>
          <li></li>
          {displayPeople(flowerGirl)}
          <li></li>
          <li></li>
        </ul>
        <div className="mb-12">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ring Bearers
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600"></p>
        </div>

        <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {displayPeople(ringBearers)}
        </ul>
      </div>
    </div>
  );
}
