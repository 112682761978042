import Board from "./Board";
import Score from "./Score";
import "./Game.css";
import GameProvider from "./context/game-context";
import { GameContext } from "./context/game-context";
import { useContext } from "react";

export function Game() {
  const { resetTiles } = useContext(GameContext);

  const newGame = () => {
    resetTiles();
  };

  return (
    <GameProvider>
      <div className="twenty48 inline-block">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <header>
          {/* <h1>Get engaged!</h1> */}
          {/* <button
            className="ml-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            onClick={newGame}
          >
            New Game
          </button> */}
          <Score />
        </header>
        <p className="hidden mb-4 sm:block">
          <strong>HOW TO PLAY:</strong> Use your arrow keys to move the tiles. Tiles with the same
          image (or number) merge into one when they touch. The goal is to get Krystal and Debanjan
          engaged (2048)!
        </p>
        <p className="block mb-4 sm:hidden">
          <strong>HOW TO PLAY:</strong> Swipe with your fingers to move the tiles. Tiles with the
          same image (or number) merge into one when they touch. The goal is to get Krystal and
          Debanjan engaged (2048)!
        </p>
        <main>
          <Board />
        </main>
      </div>
    </GameProvider>
  );
}
