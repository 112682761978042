import "./Home.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { onSnapshot, collection } from "firebase/firestore";
import db from "../firebase";

export function Home() {
  const [IsGuestLoggedIn, setIsGuestLoggedIn] = useState(true);

  useEffect(() => {
    const prevSecretPassword = atob(localStorage.getItem("kideejsecret"));

    onSnapshot(collection(db, "secret_password"), (snapshot) => {
      const secretPassword = snapshot.docs[0].data().value;
      if (prevSecretPassword === secretPassword) {
        setIsGuestLoggedIn(true);
      } else {
        setIsGuestLoggedIn(false);
      }
    });
  });

  useEffect(() => {
    const wedding = new Date("2024-11-08").getTime();

    // countdown
    setInterval(function () {
      // get today's date
      const today = new Date().getTime();
      // get the difference
      let diff = wedding - today;

      // math
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);
      // display
      if (document.getElementById("timer")) {
        document.getElementById("timer").innerHTML =
          '<div class="days"> \
  <div class="numbers">' +
          days +
          '</div>days</div> \
<div class="hours"> \
  <div class="numbers">' +
          hours +
          '</div>hours</div> \
<div class="minutes"> \
  <div class="numbers">' +
          minutes +
          '</div>minutes</div> \
<div class="seconds"> \
  <div class="numbers">' +
          seconds +
          "</div>seconds</div> \
</div>";
      }
    }, 1000);
  });

  return (
    <div className="bg-white">
      <div className="bg-header relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Krystal & Debanjan
            </h1>
            {/* <p className="mt-6 text-lg leading-8 text-white">Cuties and patooties!!</p> */}
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/events"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                See Events
              </Link>
              {!IsGuestLoggedIn && (
                <a
                  href="https://withjoy.com/kideej/rsvp"
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  RSVP <span aria-hidden="true"> →</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-header3 h-[600px] relative isolate px-6 py-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <p className="text-xl text-black bg-white md:text-2xl">
            We are so excited! Blah blah blah Blah blah blah Blah blah blah Blah blah blah Blah blah
            blah Blah blah blah Blah blah blah Blah blah blah Blah blah blah Blah blah blah Blah
            blah blah Blah blah blah Blah blah blah Blah blah blah Blah blah blah Blah blah blah
          </p>
        </div>
      </div>

      <div className="bg-header2 h-[600px] relative isolate px-6 py-14  lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <blockquote className="bg-black font-serif mx-4 p-4 text-center text-white md:p-8">
            <p className="font-bold italic text-3xl">&ldquo;Always.&rdquo;</p>
          </blockquote>
        </div>
      </div> */}

      <div className="container">
        {/* <div className="balloon white">
          <div className="star-red"></div>
          <div className="face">
            <div className="eye"></div>
            <div className="mouth happy"></div>
          </div>
          <div className="triangle"></div>
          <div className="string"></div>
        </div> */}

        <div className="balloon red hidden md:block">
          <div className="star"></div>
          <div className="face">
            <div className="eye"></div>
            <div className="mouth happy"></div>
          </div>
          <div className="triangle"></div>
          <div className="string"></div>
        </div>

        <div className="balloon blue hidden md:block">
          <div className="star"></div>
          <div className="face">
            <div className="eye"></div>
            <div className="mouth happy"></div>
          </div>
          <div className="triangle"></div>
          <div className="string"></div>
        </div>
        <div id="timer"></div>
        <h1>Wedding Countdown!</h1>
      </div>
    </div>
  );
}
