import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  containerWidthMobile,
  containerWidthDesktop,
  mergeAnimationDuration,
  tileCountPerDimension,
} from "./constants";
import "./Game.css";
import usePreviousProps from "./hooks/use-previous-props";

export default function Tile({ position, value }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const isWideScreen = useMediaQuery({ minWidth: 512 });
  const containerWidth = isWideScreen ? containerWidthDesktop : containerWidthMobile;

  const [scale, setScale] = useState(1);
  const previousValue = usePreviousProps(value);
  const hasChanged = previousValue !== value;

  const positionToPixels = (position) => (position / tileCountPerDimension) * containerWidth;

  useEffect(() => {
    if (hasChanged) {
      setScale(1.1);
      setTimeout(() => setScale(1), mergeAnimationDuration);
    }
  }, [hasChanged]);

  const style = {
    left: positionToPixels(position[0]),
    top: positionToPixels(position[1]),
    transform: `scale(${scale})`,
    zIndex: value,
  };

  const showNumberVersion = true;

  function renderTileImage(value) {
    if (showNumberVersion) {
      switch (value) {
        case 2: // babies
          return <img className="h-full max-w-full rounded" src="/game/2.png" alt="" />;
        case 4: // toddlers
          return <img className="h-full max-w-full rounded" src="/game/4.png" alt="" />;
        case 8: // teenagers
          return <img className="h-full max-w-full rounded" src="/game/8.png" alt="" />;
        case 16: // college
          return <img className="h-full max-w-full rounded" src="/game/16.png" alt="" />;
        case 32: // first pic
          return <img className="h-full max-w-full rounded" src="/game/32.png" alt="" />;
        case 64:
          return <img className="h-full max-w-full rounded" src="/game/64.png" alt="" />;
        case 128:
          return <img className="h-full max-w-full rounded" src="/game/128.png" alt="" />;
        case 256:
          return <img className="h-full max-w-full rounded" src="/game/256.png" alt="" />;
        case 512:
          return <img className="h-full max-w-full rounded" src="/game/512.png" alt="" />;
        case 1024:
          return <img className="h-full max-w-full rounded" src="/game/1024.png" alt="" />;
        case 2048: // engaged
          return <img className="h-full max-w-full rounded" src="/game/2048_2.png" alt="" />;
        default:
          return value;
      }
    } else {
      switch (value) {
        case 2: // babies
          return <img className="h-full max-w-full rounded" src="/game/babies.png" alt="" />;
        case 4: // toddlers
          return <img className="h-full max-w-full rounded" src="/game/toddlers.png" alt="" />;
        case 8: // teenagers
          return <img className="h-full max-w-full rounded" src="/game/teen.png" alt="" />;
        case 16: // college
          return <img className="h-full max-w-full rounded" src="/game/college.png" alt="" />;
        case 32: // first pic
          return <img className="h-full max-w-full rounded" src="/game/first_pic.jpg" alt="" />;
        case 64:
          return <img className="h-full max-w-full rounded" src="/game/amigrad.png" alt="" />;
        case 128:
          return (
            <img className="h-full max-w-full rounded" src="/game/jamiengagement.png" alt="" />
          );
        case 256:
          return <img className="h-full max-w-full rounded" src="/game/jayswedding.png" alt="" />;
        case 512:
          return <img className="h-full max-w-full rounded" src="/game/derryswedding.png" alt="" />;
        case 1024:
          return <img className="h-full max-w-full rounded" src="/game/photoshoot.png" alt="" />;
        case 2048: // engaged
          return <img className="h-full max-w-full rounded" src="/game/2048.png" alt="" />;
        default:
          return value;
      }
    }
  }

  return (
    <div className={classNames("tile", `tile${value}`)} style={style}>
      {renderTileImage(value)}
    </div>
  );
}
