import "./ClothingGuide.css";
import React from "react";

function ClothingCard3() {
  return (
    <>
      <div
        id="church-reception"
        className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5"
      >
        <h3 className="text-2xl font-semibold">Saturday November 9th</h3>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Church & Reception</span>
        </div>
        <img
          className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
          src={"/clothing-guide/ChurchReceptionOutfits.png"}
          alt=""
        />
        <p className="font-light text-gray-500 pt-4 pb-4 sm:text-lg">
          <b>Attire</b>: Indian or Western Formal
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Indian</b>: lehenga, sari, anarkali, sherwani
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Western</b>: suit with tie or bowtie, long dress
        </p>
      </div>
    </>
  );
}

export function ClothingGuideChurchCeremonyReception(props) {
  return (
    <>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">{ClothingCard3()}</div>
      </section>
    </>
  );
}
