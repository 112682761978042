import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isBridalPartyLoggedIn: false,
    isLoggedIn: false,
    isSecretLoggedIn: false,
  },
  reducers: {
    bridalPartyLogIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.isLoggedIn = true;
    },
    bridalPartyLogOut: (state) => {
      state.isLoggedIn = false;
    },
    logIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
    },
    secretLogIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.isSecretLoggedIn = true;
    },
    secretLogOut: (state) => {
      state.isSecretLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { bridalPartyLogIn, bridalPartyLogOut, logIn, logOut, secretLogIn, secretLogOut } =
  authSlice.actions;

export default authSlice.reducer;
