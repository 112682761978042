import "./FlipBook.css";
import HTMLFlipBook from "react-pageflip";
import React from "react";
import { OurStoryPages } from "../OurStory/OurStoryPages";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Page = React.forwardRef((props, ref) => {
  const { header, pageNumber, description, image } = props.page;

  let extraClass = "right-page";
  if (pageNumber === 1) {
    extraClass = "right-page";
  } else if (pageNumber % 2 === 0) {
    extraClass = "left-page";
  }

  const renderContent = () => {
    switch (pageNumber) {
      case 1:
        return (
          <>
            <img className="movie-image" src={"/our-story/1.png"} alt="" />
          </>
        );
      case 2:
        return (
          <>
            <img className="movie-image" src={"/our-story/2.png"} alt="" />
          </>
        );
      case 3:
        return (
          <>
            <img className="movie-image" src={"/our-story/3.png"} alt="" />
          </>
        );
      case 4:
        return (
          <>
            <img className="movie-image" src={"/our-story/4.png"} alt="" />
          </>
        );
      case 5:
        return (
          <>
            <img className="movie-image" src={"/our-story/5.png"} alt="" />
          </>
        );
      case 6:
        return (
          <>
            <img className="movie-image" src={"/our-story/6.png"} alt="" />
          </>
        );
      case 7:
        return (
          <>
            <img className="movie-image" src={"/our-story/7.png"} alt="" />
          </>
        );
      case 8:
        return (
          <>
            <img className="movie-image" src={"/our-story/8.png"} alt="" />
          </>
        );
      case 9:
        return (
          <>
            <img className="movie-image" src={"/our-story/9_cont.png"} alt="" />
          </>
        );
      default:
        return <p>Hello</p>;
    }
  };

  return (
    <div className={classNames("bg-white", extraClass)} ref={ref}>
      <div className="page-content">{renderContent()}</div>
    </div>
  );
});

// https://github.com/Nodlik/react-pageflip
export function FlipBook(props) {
  return (
    <HTMLFlipBook
      size="stretch"
      width={400}
      height={400}
      minWidth={315}
      maxWidth={1000}
      minHeight={400}
      maxHeight={1533}
      maxShadowOpacity={0.5}
      showCover={true}
      mobileScrollSupport={true}
    >
      {OurStoryPages.map((page, index) => (
        <Page page={page} key={index}></Page>
      ))}
    </HTMLFlipBook>
  );
}
