import "./ClothingGuide.css";
import React, { useEffect, useState } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import db from "../firebase";

function BridalPartyClothingCard() {
  return (
    <>
      <div className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5">
        {/* <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Krystal & Debanjan's</span>
        </div>
        <h3 className="text-2xl font-semibold">Bridal Party Clothing Guide</h3>
        <div className="justify-center my-4">{DownloadPdfButton()}</div>
        <p className="font-light text-gray-500 pt-2 pb-4 sm:text-lg">
          Hi everyone! Here’s a little dress guide for the wedding events to help you plan and shop
          for your outfits!
        </p> */}
        <span className="text-left flex items-center font-light text-gray-500 pb-2 sm:text-lg">
          <svg
            fill="light gray"
            height="20px"
            width="20px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 224.549 224.549"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path d="M223.476,108.41c-1.779-2.96-44.35-72.503-111.202-72.503S2.851,105.45,1.072,108.41c-1.43,2.378-1.43,5.351,0,7.729 c1.779,2.96,44.35,72.503,111.202,72.503s109.423-69.543,111.202-72.503C224.906,113.761,224.906,110.788,223.476,108.41z M112.274,173.642c-49.925,0-86.176-47.359-95.808-61.374c9.614-14.032,45.761-61.36,95.808-61.36 c49.925,0,86.176,47.359,95.808,61.374C198.468,126.313,162.321,173.642,112.274,173.642z"></path>{" "}
                <path d="M112.274,61.731c-27.869,0-50.542,22.674-50.542,50.543c0,27.868,22.673,50.54,50.542,50.54 c27.868,0,50.541-22.672,50.541-50.54C162.815,84.405,140.143,61.731,112.274,61.731z M112.274,147.814 c-19.598,0-35.542-15.943-35.542-35.54c0-19.599,15.944-35.543,35.542-35.543s35.541,15.944,35.541,35.543 C147.815,131.871,131.872,147.814,112.274,147.814z"></path>{" "}
                <path d="M112.274,92.91c-10.702,0-19.372,8.669-19.372,19.364c0,10.694,8.67,19.363,19.372,19.363 c10.703,0,19.373-8.669,19.373-19.363C131.647,101.579,122.977,92.91,112.274,92.91z"></path>{" "}
              </g>{" "}
            </g>
          </svg>
          <p className="ml-2">Visible to Bridal Party Only</p>
        </span>
        <img
          className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
          src={"/clothing-guide/Kideej Clothing Guide - Bridal Party.png"}
          alt=""
        />
      </div>
    </>
  );
}

function DownloadPdfButton() {
  return (
    <a
      className="download-button rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      href="/clothing-guide/Kideej Clothing Guide - Everyone.pdf"
      download="KiDeej_Clothing_Guide.pdf"
    >
      <svg
        className="download-icon"
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="download-arrow"
          d="M13 9L9 13M9 13L5 9M9 13V1"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Download as a pdf
    </a>
  );
}

function ClothingCard1() {
  return (
    <>
      <div
        id="intro"
        className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5"
      >
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Krystal & Debanjan's</span>
        </div>
        <h3 className="text-2xl font-semibold">Wedding Event Clothing Guide</h3>
        <div className="justify-center my-4">{DownloadPdfButton()}</div>
        <p className="font-light text-gray-500 pt-2 pb-4 sm:text-lg">
          Hi everyone! Here’s a little dress guide for the wedding events to help you plan and shop
          for your outfits!
        </p>
        <p className="font-light text-gray-500 pb-6 sm:text-lg">
          Here are some sites you can get ready made or custom Indian outfits:
        </p>
        <span className="font-light text-gray-500 pb-6 sm:text-lg">
          <ul>
            <li>
              <a
                className="link"
                href="https://www.perniaspopupshop.com"
                target="_blank"
                rel="noreferrer"
              >
                Pernia’s Pop Up Shop
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://www.kalkifashion.com"
                target="_blank"
                rel="noreferrer"
              >
                Kalki Fashion
              </a>
            </li>
            <li>
              <a className="link" href="https://shopkynah.com" target="_blank" rel="noreferrer">
                KYNAH
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://www.thesareeroom.com"
                target="_blank"
                rel="noreferrer"
              >
                The Saree Room
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://www.holichicbymegha.com"
                target="_blank"
                rel="noreferrer"
              >
                holiCHIC by Megha
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://www.houseofindya.com"
                target="_blank"
                rel="noreferrer"
              >
                House of Indya
              </a>
            </li>
            <li>
              <a className="link" href="https://www.lashkaraa.com" target="_blank" rel="noreferrer">
                Lashkaraa
              </a>
            </li>
            <li>
              <a className="link" href="https://www.etsy.com" target="_blank" rel="noreferrer">
                Etsy
              </a>
              ,{" "}
              <a className="link" href="https://www.cbazaar.com" target="_blank" rel="noreferrer">
                Cbazaar
              </a>
              ,{" "}
              <a className="link" href="https://www.amazon.com" target="_blank" rel="noreferrer">
                Amazon
              </a>
              ,{" "}
              <a className="link" href="https://www.asos.com/us/" target="_blank" rel="noreferrer">
                Asos
              </a>
              , and{" "}
              <a className="link" href="https://www.nordstrom.com" target="_blank" rel="noreferrer">
                Nordstrom
              </a>{" "}
              also have affordable options!
            </li>
          </ul>
        </span>
      </div>

      {/* <img
        className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
        src={"/clothing-guide/Kideej Clothing Guide - Everyone1.png"}
        alt=""
      />
      <img
        className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
        src={"/clothing-guide/Kideej Clothing Guide - Everyone2.png"}
        alt=""
      />
      <img
        className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
        src={"/clothing-guide/Kideej Clothing Guide - Everyone3.png"}
        alt=""
      /> */}
    </>
  );
}

function ClothingCard2() {
  return (
    <>
      <div
        id="hindu-ceremony"
        className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5"
      >
        <h3 className="text-2xl font-semibold">Friday November 8th</h3>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Hindu Ceremony</span>
        </div>
        <img
          className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
          src={"/clothing-guide/HinduCeremonyOutfits.png"}
          alt=""
        />
        <p className="font-light text-gray-500 pt-4 pb-4 sm:text-lg">
          <b>Attire</b>: Mostly Indian, or Western Semi-Formal
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Indian</b>: indowestern, sari, lehenga, kurta
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Western</b>: dress, blazer and pants
        </p>
      </div>
    </>
  );
}

function ClothingCard3() {
  return (
    <>
      <div
        id="church-reception"
        className="animate-fade-down animate-duration-800 flex flex-col p-6 mx-auto max-w-3xl text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow mb-5"
      >
        <h3 className="text-2xl font-semibold">Saturday November 9th</h3>
        <div className="flex justify-center items-baseline my-4 my-auto">
          <span className="text-4xl font-extrabold">Church & Reception</span>
        </div>
        <img
          className="animate-fade-down animate-duration-800 h-100 w-100 rounded"
          src={"/clothing-guide/ChurchReceptionOutfits.png"}
          alt=""
        />
        <p className="font-light text-gray-500 pt-4 pb-4 sm:text-lg">
          <b>Attire</b>: Indian or Western Formal
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Indian</b>: lehenga, sari, anarkali, sherwani
        </p>
        <p className="font-light text-gray-500 pb-4 sm:text-lg">
          <b>Western</b>: suit with tie or bowtie, long dress
        </p>
      </div>
    </>
  );
}

export function ClothingGuide(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Run on component mount

  const [isBridalPartyLoggedIn, setIsBridalPartyLoggedIn] = useState(false);

  useEffect(() => {
    const prevBridalPartyPassword = atob(localStorage.getItem("kideejbridalparty"));

    onSnapshot(collection(db, "bridal_party_password"), (snapshot) => {
      const bridalPartyPassword = snapshot.docs[0].data().value;
      if (prevBridalPartyPassword === bridalPartyPassword) {
        setIsBridalPartyLoggedIn(true);
      }
    });
  });

  return (
    <>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
          {isBridalPartyLoggedIn ? BridalPartyClothingCard() : <></>}
          {ClothingCard1()}
          {ClothingCard2()}
          {ClothingCard3()}
        </div>
      </section>
    </>
  );
}
