export const OurStoryPages = [
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 1,
    image: "/FirstPic.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 2,
    image: "/NoodleStreet.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 3,
    image: "/Snapchat.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 4,
    image: "/Cheesecake.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 5,
    image: "/TheProposal1.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 6,
    image: "/TheProposal1.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 7,
    image: "/TheProposal1.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 8,
    image: "/TheProposal1.jpg",
  },
  {
    header: "How we met",
    description: "We met on Hingey",
    pageNumber: 9,
    image: "/TheProposal1.jpg",
  },
];
