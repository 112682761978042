// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import FIRESTORE_API_KEY from "./apikey";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: FIRESTORE_API_KEY,
  authDomain: "krystal-debanjan.firebaseapp.com",
  projectId: "krystal-debanjan",
  storageBucket: "krystal-debanjan.appspot.com",
  messagingSenderId: "390579865043",
  appId: "1:390579865043:web:4d39e040e01d84a59ddb26",
  measurementId: "G-SY1CVBFMX2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default getFirestore();
